/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
	
}

@media screen and (max-width: 768px) {
	
}

@media screen and (max-width: 992px) {
	
}

.home {
    height: 100vh;
    background-color: aliceblue;

    .login {
        width: 30%;
        background-color: white;
        padding: 48px;
        box-shadow: 1px 0 20px rgba(0,0,0,.12);
        border-radius: 8px;

        .login-box {
            
        }
    }
}

.pass-eye {
    position: relative;

    .fa-eye, .fa-eye-slash {
        position: absolute;
        right: 8px;
        top: 9px;
        font-size: 0.9em;
    }
}

@media screen and (max-width: 1292px) {
    
}