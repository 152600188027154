.row-control{
    height: 49px;
}


.row-control-img{
    height: 49px;


    span{
        width: 40%;
    }
}