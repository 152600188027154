.menu {
    width: 95px;
    height: 100vh;
    position: sticky;
    background-color: $primary;
    top: 0;

    .menu-element {
        .logo {
            width: 75%;
            height: 80px;
        }

        .menu-element-name {
            color: white;   
            cursor: pointer;
            padding: 10px 0;
            font-size: 13px;

            i {
                font-size: 16px;
            }
        }
    }
}