/**
*   colors.scss
*   @description: Variables de colores
*/

$primary: #299cd2;
$secondary: #666666;
$pink: #B7275B;
$success: #03a74e;
$info: #17a2b8;
$warning: #ffc107;
$danger: #ea4235;

$black: #000000;
$white: #ffffff;
$gray: #666;
$darkgray: #4e4e4e;
$lightGray: #f5f5f5;
$lightBlue: #51aecf;
$lightGray2: #e6e6e6;
$orange: #FF9800;
$green: #4fa72c;

/* Classes de colores */

.color-white {
	color: $white;
}

.color-black {
	color: $black;
}

.color-gray {
	color: $gray;
}

.color-darkgray {
	color: $darkgray;
}

.color-green {
	color: $green;
}

.color-primary {
	color: $primary;
}

.color-secondary {
	color: $secondary;
}

.color-success {
	color: $success;
}

.color-info {
	color: $info;
}

.color-warning {
	color: $warning;
}

.color-danger {
	color: $danger;
}