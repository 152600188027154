.sidepanel {
	background-color: #0e0e0e47;
	height: 100vh; 
	width: 0; 
	position: fixed; 
	top: 0;
	right: 0;
	z-index: 1; 

	/* The sidepanel menu */
	.sidepanel-content {
		height: 100vh; /* Specify a height */
		width: 0; /* 0 width - change this with JavaScript */
		position: fixed; /* Stay in place */
		z-index: 1; /* Stay on top */
		top: 0;
		right: 0;
		background-color: white; /* Black*/
		overflow-x: hidden; /* Disable horizontal scroll */
		//padding-top: 60px; /* Place content 60px from the top */
		transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
		box-shadow: 0 0 112px rgb(33 33 33 / 25%);

		.header-title {
			border-bottom: 1px solid #eee;
			padding: 16px 24px;

			h4 {
				font-weight: 500;
				font-size: 1.3em;
			}
		}

		/* The sidepanel links */
		a {
			padding: 8px 8px 8px 32px;
			text-decoration: none;
			font-size: 25px;
			color: #818181;
			display: block;
			transition: 0.3s;

			/* When you mouse over the navigation links, change their color */
			&::hover {
				color: #f1f1f1;
			}
		}

		/* Position and style the close button (top right corner) */
		.closebtn {
			position: absolute;
			top: 2px;
			right: 6px;
			font-size: 24px;
			margin-left: 50px;
			cursor: pointer;
		}

		.content-inputs {
			padding: 24px;
		}
	}
}
