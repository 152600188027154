/**
*   header.scss
*   @description: Estilos del Header
*/
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");

.header {
    padding: 24px 0;

    .fa-user {
        color: $green;
    }

    .username {
        padding: 0 8px;
        border-right: 1px solid black;
    }

    .logout {
        padding: 0 0 0 8px;
    }
}

.header-modals {
    padding: 8px 8px 8px 16px;
    border-radius: 4px 4px 0 0;
    background-color: $black;
    width: 100%;
    position: relative;

    .btn-modal-close {
        position: absolute;
        right: 16px;
        top: 10px;
        color: white;
        font-size: 1rem;

        &:hover {
            color: $primary;
            transition: color 300ms ease-in-out;
        }
    }
}

.header-close {
    border-radius: 4px 4px 0 0;
    background-color: $black;
    width: 100%;

    .btn-modal-close {
        color: white;
        font-size: 1.2rem;

        &:hover {
            color: $primary;
            transition: color 300ms ease-in-out;
        }
    }
}

/*    TABLET DEVICES    */

@media screen and (max-width: 1024px) {

}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
    
}

@media screen and (max-width: 320px) {
    
}