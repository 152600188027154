.barra-notificaciones {
    height: 100vh;
    top: 0;
    position: fixed;
    right: 0;
    width: 25%;
    z-index: 10000;
    background-color: #f6f7f8;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);

    .content-noti {
        position: relative;

        .header-noti {
            padding: 16px 6px 0 16px;

            .close-noti {
                width: auto;
                cursor: pointer;

                h4 {
                    font-weight: 500;
                    font-size: 1.4em;
                    padding: 4px 13px;
                }

                /*&:hover{
                    background-color: #6666660d;
                }*/
            }

            i {
                font-size: 0.9em;
            }

            .det {
                h4 {
                    font-size: 1.1rem;
                }
            }
        }

        .load {
            i {
                padding-right: 0;
                font-size: 0.9em;
                color: gray;
            }

            h5 {
                font-size: 0.9em;
            }

            cursor: pointer;

            &:hover {
                //background-color: $primary;
                i {
                    color: #4e4e4e;
                }
            }
        }

        .content-noti {
            overflow-y: scroll;
            height: 93vh;
            overflow-x: hidden;

            .subnoti {
                .encabezado {
                    padding-top: 16px;

                    .subName {
                        padding-left: 16px;
                    }

                    .subCheck {
                        padding-right: 16px;
                    }

                    .spin {
                        padding-right: 16px;

                        i {
                            padding: 0;
                            font-size: 1rem;
                        }
                    }
                }

                .noti {
                    margin-top: 8px;
                    border-top: 1px solid #66666638;

                    .noti-box {
                        padding: 8px 16px;
                        border-bottom: 1px solid #66666638;
                        background-color: white;

                        h5 {
                            font-size: 0.9em;
                        }

                        .user {
                            padding-right: 16px;

                            .circle-user {
                                padding: 10px;
                                background-color: #eab7908a;
                                border-radius: 50%;
                            }

                            i {
                                font-size: 2.4em;
                                color: #ffc107a1;
                                padding-right: 0;
                                cursor: default;
                            }
                        }

                        .noti-text {
                            h5 {
                                font-weight: 500;
                                //padding-left: 0;
                            }

                            .date-noti {
                                h5 {
                                    font-size: 0.7em;
                                    color: gray;
                                }
                            }

                             .read {
                                padding-left: 8px;
                                
                                .fa-circle {
                                    position: relative;
                                    font-size: 0.5em;
                                    padding-right: 0;
                                    top: 0;
                                    left: 0;
                                }

                                .gray {
                                    color: lightgray;
                                }
                            }
                        }
                    }

                    .detail {
                        &:hover {
                            background-color: #6666660d;
                        }
                    }
                }
            }
        }

        .content-noti-detail {
            overflow-x: auto;
            height: 93vh;
            margin-top: 8px;
            border-top: 1px solid #66666638;

            .detail-box {
                padding: 10px 16px;
                border-bottom: 1px solid #66666638;
                background-color: white;

                .img-detail {
                    padding: 0 16px;

                    .circle-img {
                        background-size: cover;
                        border-radius: 50%;
                        height: 60px;
                        width: 60px;
                        background-position: center;
                    }
                }

                .info-detail {
                    padding-left: 8px;

                    h5, h4 {
                        font-size: 0.9em;
                    }

                    .white-space-4 {
                        height: 2px;
                    }
                }
            }
        }

        .noti-message {
            padding: 24px 0;
        }
    }
}

.scroll-header {
    top: 0;
}