.pagination .page-link {
    background-color: white;
    padding: 6px 11px;
    color: $secondary;
    float: left;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid lightgray;
    margin: 0 3px;
    //border-radius: 20px;
}

.page a:hover:not(.active) {
    //background-color: $primary;
    color: $secondary;
    border-color: $primary;
    cursor: pointer;
}

.pagination .current-page a {
    border-color: $primary;
    color: black;
}