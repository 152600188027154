.projects {
  position: relative;

  .terms-gral {
    .conditions {
      .details {
        //border: 1px solid #299cd2;
        height: 100%;
        width: 24%;
        padding: 16px;
        border-radius: 5px;
      }
    }

    .accordion {
      .accordion-item {
        .accordion-title {
          border-left: 8px solid $primary;
          color: #333;
          background-color: #eee;
          padding: 8px 16px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          &:hover {
            cursor: pointer;
          }

          .a-title {
            //width: 10%;
          }
        }

        i.toggle {
          align-self: center;
          margin-left: auto;
          transition: all 0.35s ease;
        }

        i.toggle[aria-expanded="true"] {
          transform: rotateZ(90deg);
        }

        .accordion-content {
          overflow: hidden;
          max-height: 1000px;
          transition: max-height 1s ease-in-out;
          border-left: 1px solid #eee;
          border-right: 1px solid #eee;
          border-bottom: 1px solid #eee;
          border-top: 1px solid #fff;
        }

        .accordion-content[aria-expanded="true"] {
          max-height: 0;
          transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        }

        .content {
          padding: 16px 24px;
        }
      }
    }
  }

  .terms {
    .actions {
      background-color: #eee;
      padding: 8px 12px 8px 4px;
    }

    .accordion-content {
      position: relative;

      .actions-box {
        position: absolute;
        top: -1px;
        background: white;
        right: 0;
        border: 1px solid #eee;

        .pestaña-top {
          padding: 4px 16px;
          border-bottom: 1px solid #eee;

          &:hover {
            background-color: #eee;
          }
        }

        .pestaña-bottom {
          padding: 4px 16px;
          
          &:hover {
            background-color: #eee;
          }
        }
      }
    }
  }

  .box-users {
    .box-u {
      padding: 3px 0;
    }
  }
}

#drop-area {
  position: relative;
}

#fileElem {
  display: none;
}

.upload-label {
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
}
.upload-label:hover {
  background-color: #e9e9e9;
}

.upload-label:active {
  background-color: #f6f6f6;
}

.upload-text {
  color: #666;
  user-select: none;
}

.image {
  min-width: 100%;
  max-width: 100%;
  height: 140px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

//Second try
.file-upload-content {
  display: none;
  text-align: center;
}
.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.image-upload-wrap {
  position: relative;
  height: 100px;
  border: 4px solid #d0d7de;
  margin-left: 10px;
  margin-right: 10px;
}
.image-upload-wrap:hover {
  background-color: transparent;
  border: 4px dashed #d0d7de;
}
.text-information {
  margin-top: 30px;
  text-align: center;
}

//Drag and drop
.drag-and-drop {
  display: inline-block;
  position: relative;

  .content {
    border: dashed grey 4px;
    background-color: rgba(255,255,255,.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    .d-a-d {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      text-align: center;
      color: grey;
      font-size: 36;
    }
  }
}