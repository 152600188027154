/**
*   modals.scss
*   @description: Estilos de los Modales
*/


/**
*   Generales
*       #modal-newsletter
*       #modal-promotion
*       #modal-alert
*/

.image-zoom-modal{
    display: none; 
    position: fixed; 
    z-index: 100; 
    /*padding-top: 10px; */
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    /*background-color: rgb(0,0,0); */
    background-color: rgba(119, 117, 117, 0.582);

}

#image-zoom-modal {
    display: none;
}

.modal-content-zoom {
    background-color: #efefde00;
    margin: 85px;
    //align-content: center;
    //justify-content: center;
    border-radius: 5px;
    padding-right: 20px;
    padding-left: 20px;
    width: 40%;
    max-height: 80%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.img-zoom{
    max-height: 60vh;
}

#modal-close-zoom
{
    background-color: #efefde00;
    color: white;
    font-weight: 900;
    cursor: pointer;
}


.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //overflow: hidden;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    width: 80%;    
    margin: auto;
    position: relative;
    background-color: $white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;    
}

.modal-header {
    padding: $paddingMedium 0;
    background-color: $secondary;
    
    h3 {
        color: white;
    }
}

.modal-body-container {
    align-items: center;
}

.modal-delete .modal-content {
    width: 40%;
}

#modal-newsletter .modal-content  {
    width: 60%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    background-color: $white;

    p, form {
        width: 70%;
    }     

    #close-modal-newsletter {
        position: absolute;
        top: 16px;
        right: 16px;
        i {
            font-size: $fontRegular;
        }
    }

}

.swal-text, .swal-title {
    font-family: $Roboto !important;
    //color: black;
    text-align: center;
}

.swal-text:first-child {
    margin-top: 24px;
}

.swal-button-container {
    width: auto;
}

.swal-button--confirm {
    background-color: $green !important;
    //color: black;
}

 .product-img {
    background: #9e9e9e4d;
    position: relative;
    border-radius: 4px;

    img {
        height: 140px;
    }

    .rd-parallax-gallery {
        /*opacity: 0;
        transition: .3s all ease;*/
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: 300ms all ease;
        //visibility: hidden;
        opacity: 0;
        //transform: translate(-50%, 100%);

        .top-text i {
            color: white;
            font-size: 1.8em;
            cursor: pointer;
        }
    }

    &:hover> .rd-parallax-gallery {
        background: linear-gradient(to bottom, transparent 5%, rgba(black, 0.8));
        //visibility: visible;
        opacity: 1;
        cursor: pointer;
    }
}

.page-load {
    .fa-spinner {
        font-size: 3em;
        color: $gray;
    }
}

.ct-toast-success {
  font-size: $Roboto;
}

@media screen and (max-width: 992px) {

    #modal-newsletter .modal-content  {
        width: 70%;    

        p, form {
            width: 70%;
        }     

        #close-modal-newsletter {
            position: absolute;
            top: 16px;
            right: 16px;
            i {
                font-size: $fontRegular;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #modal-newsletter .modal-content  {
        width: 80%;    

        p, form {
            width: 70%;
        }     

        #close-modal-newsletter {
            position: absolute;
            top: 16px;
            right: 16px;
            i {
                font-size: $fontRegular;
            }
        }

    }
}

@media screen and (max-width: 576px) {
    #modal-newsletter .modal-content  {
        width: 95%;
        background-position: left;
        p, form {
            width: 80%;
        }
    }
}

@media screen and (max-width: 992px) {
    #modal-promotion .modal-content {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    #modal-promotion .modal-content {
        width: 80%;
    }
}

@media screen and (max-width: 576px) {
    #modal-promotion .modal-content {
        width: 95%;
    }
}

@media screen and (max-width: 992px) {
    #modal-alert .modal-content {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    #modal-alert .modal-content {
        width: 80%;
    }
}

@media screen and (max-width: 576px) {
    #modal-alert .modal-content {
        width: 95%;
    }
}