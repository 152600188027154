.calendar {
	.calendar-content {
		.fc-toolbar-chunk {
			display: flex;
			.fc-button {
				cursor: pointer;
				font-family: $Roboto;
				font-size: $fontSmall;
				font-weight: $weightBold;
				background-color: transparent;
				transition: all 200ms ease-in-out;
				&:active {
					transform: scale(0.95);
				}
				width: 50%;
				background-color: $gray;
				text-align: center;
				font-size: 14px;
				font-weight: 400;
				border-radius: 3px;
				padding: $paddingNormal $paddingSemi;

				.fc-icon {
					color: white;
				}
				.fc-button-primary {
					&:hover {
						background-color: rgba($primary, 0.8);
						color: black;
					}
				}
			}
		}

		.information-box {
			width: 100%;
			//padding-left: 20px;
		}
	}
}
