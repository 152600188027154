#aside {
    /*max-width: 17%;
    min-height: 100vh;
    background: white;*/
    height: 100%;
    max-width: 21%;
    position: sticky;
    height: 100vh;
    z-index: 1;
    top: 0;
    color: $white;
    left: 0;
    background-color: #3F3F43;
    overflow-x: hidden;
    transition: 300ms;

    .aside .menu-element {
        color: white;   
        cursor: pointer;
        border-bottom: 3px /*rgba(#ddd,0.5)*/ solid white;
        background: #3F3F43;
        padding-left: $paddingLarge;
    }

    .close-aside {
        display: none;
    }

    .aside .menu-element:hover {
        background-color: #e9ebed61;
        //border-bottom: 1px solid #eee;

        .menu-element-name a {
            color: white;
        }

        .icon i {
            color: white;
        }
    }

    .aside .menu-element .icon {
        width: 25%;

        i {
            color: white;
            font-size: 1.2em;
        }
    }

    .aside .menu-element .menu-element-name {    
        font-weight: $weightMedium;    
    }

    .aside .menu-element .menu-element-name a {
        width: 80%;
        padding-left: $paddingLarge;
        padding: $paddingMedium 0;
        color: white;
        font-size: 1em;
    }

    .aside img {
        width: 65%;
    }

     .eys {
        a {
            width: 80%;
            padding-left: $paddingLarge;
            padding: $paddingMedium 0;
            color: white;
            font-size: 1em;
        }
    } 
}

/*@media screen and (max-width: 1400px) {
    #aside {
        max-width: 0% !important;
        position: fixed;
        justify-content: center;

        .aside img {
            width: 50%;
        }

        .close-aside {
            position: absolute;
            z-index: 3;
            display: block !important;
            right: 21px;
            top: 8px;

            h4 {
                text-align: end !important;
                font-size: 2.3em;
            }
        }

        .menu-element {
            border-bottom: 0 !important;
            justify-content: center;
            padding-left: 0 !important;

            &:hover {
                background-color: none;
            }

            .icon {
                display: none;
            }

            .menu-element-name, .eys {
                width: 100%;
                justify-content: center;

                a {
                    text-align: center;
                    font-weight: 600;
                    font-size: 1.8em !important;
                    line-height: 36px;
                }
            }
        }
    }
}*/

@media screen and (max-width: 992px) {
    #aside {
        max-width: 0% !important;
        position: fixed;
        justify-content: center;

        .aside img {
            width: 50%;
        }

        .close-aside {
            position: absolute;
            z-index: 3;
            display: block !important;
            right: 20px;
            top: 0;

            h4 {
                text-align: end !important;
                font-size: 2.3em;
            }
        }

        .menu-element {
            border-bottom: 0 !important;
            justify-content: center;
            padding-left: 0 !important;

            &:hover {
                background-color: none;
            }

            .icon {
                display: none;
            }

            .menu-element-name, .eys {
                width: 100%;
                justify-content: center;

                a {
                    text-align: center;
                    font-weight: 600;
                    font-size: 1.9em !important;
                    line-height: 40px;
                }
            }
        }
    }
    
    /*#aside {
        max-width: 28%;

        .eys {
            a {
                //visibility: hidden;
                //font-size: 0;
                line-height: 0;
                padding: 8px 0;
                color: transparent;

                &::before {
                    content: "E / S";
                    font-size: 1.1em !important;
                    color: white !important;
                    display: block !important;
                    text-indent: 0;
                    line-height: 38px;
                }
            }
        }            
    }*/   
}

/*    MOBILE DEVICES    */

@media screen and (min-width: 321px) and (max-width: 576px) {
    #aside {
        max-width: 0% !important;
        position: fixed;
        justify-content: center;

        .aside img {
            width: 60%;
        }

        .close-aside {
            position: absolute;
            z-index: 3;
            display: block !important;
            right: 13px;
            top: 4px;

            h4 {
                text-align: end !important;
                font-size: 1.4em;
            }
        }

        .menu-element {
            border-bottom: 0 !important;
            justify-content: center;
            padding-left: 0 !important;

            &:hover {
                background-color: none;
            }

            .icon {
                display: none;
            }

            .menu-element-name, .eys {
                width: 100%;
                justify-content: center;

                a {
                    text-align: center;
                    font-weight: 600;
                    font-size: 1.1em !important;
                    line-height: 16px;
                    padding: 14px 0 !important;
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {
    #aside {
        max-width: 0% !important;
        position: fixed;
        justify-content: center;

        .aside img {
            width: 60%;
        }

        .close-aside {
            position: absolute;
            z-index: 3;
            display: block !important;
            right: 13px;
            top: 4px;

            h4 {
                text-align: end !important;
                font-size: 1.4em;
            }
        }

        .menu-element {
            border-bottom: 0 !important;
            justify-content: center;
            padding-left: 0 !important;

            &:hover {
                background-color: none;
            }

            .icon {
                display: none;
            }

            .menu-element-name, .eys {
                width: 100%;
                justify-content: center;

                a {
                    text-align: center;
                    font-weight: 600;
                    font-size: 1.1em !important;
                    line-height: 16px;
                    padding: 11px 0 !important;
                }
            }
        }
    }
}