.table-responsive th {
	font-size: 15px;
	color: #fff;
	line-height: 1.2;
	//text-align: left;
	//font-weight: unset!important;
	padding: 10px 0 10px 20px;
	font-weight: 400;
}

.table-responsive tr {
	color: #fff;
	background: $primary;
}

.table-row td {
	border-bottom: 1px solid $lightGray;
	background-color: white;
	padding: 8px 0 8px 20px;
	//text-align: left;
	font-weight: 400;
	font-size: 15px;
}

.clients-table,
.users-table,
.projects-table {
	border-radius: 6px;
	overflow: hidden;
	border-right: 1px solid $lightGray;
	border-left: 1px solid $lightGray;
}

/*.projects-table {
    .table-responsive tr {
        i {
            z-index: 4;
        }

        &:hover {
            td {
                background-color: #eee !important;
            }
        }
    }
}*/

.conditions-table,
.documents-table,
.table-terms,
.calendar-table {
	tr {
		background: #fff;
	}

	th {
		color: #299cd2;
		font-weight: 500;
		border-bottom: 1px solid #eee;
	}
}

.users-table-permisos {
	tr {
		background: #fff;
	}

	th {
		font-weight: 400;
		color: #4e4e4e;
		padding: 10px 0 10px 0px;
		font-size: 1rem;
	}

	td {
		padding: 0 0 6px 0;
	}
}

.user-table {
	th {
		background-color: $secondary;
		color: #fff;
		border: 1px solid $lightGray;
		font-weight: 500;
		font-size: 14px;
	}

	i {
		font-size: 1.2em;
	}

	.image {
		padding: 0 21px;
	}

	.desc {
		padding: 0 28px;
	}
}

.calendar-table {
	height: auto;

	td {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
	}
}