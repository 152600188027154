

    p{
        font-size: .93rem;
        line-height: 22px;
        color: #000;
		padding-left: 25px;
		font-weight: 400;

    }
    
    .input {
        color: $gray;
        padding: 6px;
        //border-bottom: 2px solid rgba($gray, 0.5);
		&:focus { 
			border-bottom: 2px solid rgba($primary, 0.8); 
		}
		font-family: $Roboto;
	}

	.input-disabled {
		color: $gray;
        padding: 6px;
        border-bottom: 2px solid rgba($secondary, 0.5);
		font-family: $Roboto;
		background-color: #80808021;
	}

	.input-search {
		position: relative;
		//width: 40%;

		.input-filter {
			width: 40%;
			padding: 7px 12px 7px 35px;
			font-size: 14px;
			//border: 1px solid #c4c8cb;
		}

		i {
			width: 4%;
			left: 13px;
			top: 10px;
			color: #9e9e9e !important;
			cursor: none;
			font-size: 0.9rem;
			position: absolute;
		}
	}

	.input-select {
		padding: 5px;
		opacity: 1;
	}

	/* The container */
	.container-check {
		display: block;
		height:16px;
		width:90%;
		position: relative;
		//padding-left: 35px;
		cursor: pointer;
		//font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		border-radius: 0;
	}
	
	/* Hide the browser's default checkbox */
	.container-check input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		border-radius: 0;
	}
	
	/* Create a custom checkbox */
	/*.checkmark {
		position: absolute;
		//top: 0;
		//left: 0;
		height: 18px;
		width: 18px;
		background-color: rgb(211, 211, 211);
		border-radius: 0 !important;
	}*/
	
	/* On mouse-over, add a grey background color */
	/*.container-check:hover input ~ .checkmark {
		background-color: #ccc;
	}*/
	
	/* When the checkbox is checked, add a blue background */
	/*.container-check input:checked ~ .checkmark {
		background-color: $secondary;
	}*/
	
	/* Create the checkmark/indicator (hidden when not checked) */
	/*.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}*/
	
	/* Show the checkmark when checked */
	/*.container-check input:checked ~ .checkmark:after {
		display: block;
	}*/
	
	/* Style the checkmark/indicator */
	/*.container-check .checkmark:after {
		left: 6px;
		top: 1px;
		width: 6px;
		height: 13px;
		border: solid white;
		border-width: 0 2px 3px 0;
		transform: rotate(45deg);
	}*/

	/* Checkbox style */
	input[type=checkbox] + label, input[type=radio] + label {
		cursor: pointer;
		position: relative;
		margin: auto;
		width: 18px;
		height: 18px;
		-webkit-tap-highlight-color: transparent;
		transform: translate3d(0, 0, 0);
		&:before {
			content: "";
			position: absolute;
			top: -15px;
			left: -15px;
			width: 48px;
			//height: 48px;
			border-radius: 50%;
			background: rgba($primary, 0.8);
			opacity: 0;
			transition: opacity 0.2s ease;
		}
		svg {
			position: relative;
			z-index: 1;
			fill: none;
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke: #c8ccd4;
			stroke-width: 1.5;
			transform: translate3d(0, 0, 0);
			transition: all 0.2s ease;
			path {
				stroke-dasharray: 60;
				stroke-dashoffset: 0;
			}
			polyline {
				stroke-dasharray: 22;
				stroke-dashoffset: 66;
			}
		}
		&:hover {
			&:before {
				opacity: 1;
			}
			svg {
				stroke: $primary;
			}
		}
	}

	input[type=checkbox], input[type=radio] {
		&:checked {
			+ {
				.check {
					svg {
						stroke: $primary;
						path {
							stroke-dashoffset: 60;
							transition: all 0.3s linear;
						}
						polyline {
							stroke-dashoffset: 42;
							transition: all 0.2s linear;
							transition-delay: 0.15s;
						}
					}
				}
			}
		}
	}

	.div-small {
		width: 60%;
	}

	.space {
		margin-left: $marginMedium;
	}

	.label-gray {
		background-color: #eaeaea;
    	padding: 2px 16px;
	}

	.text-area-gray {
		background-color: #eaeaea;
		padding: 8px;
		width: 100%;
		font-size: $fontText;
		//min-height: 100px;
	}

/* Estilos label para filas con un solo input */

	.label-normal { 
		width: 20%;
    }
    .label-small { 
		width: 25%;
	}

	.label-extra { 
		width: 31%;
	}

	.label-medium { 
		width: 35%;
	}

	.label-big { 
		width: 45%;
    }
    
    .label-medium-row { 
		width: 42%;
	}

/* Estilos label para filas con dos inputs */
	.label-duo-huge {
		width: 115%;
	}

	.label-duo-big {
		width: 90%;
	}

	.label-duo-normal {
		width: 53%;
	}

    .label-duo-small {
        width: 40%;
        margin-right: 16px;
    }
    
/* Estilos label para filas con tres inputs */

	.label-triple-huge {
		width: 130%;
	}




/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {


}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {    
    
    /*.input{
        margin-top: 16px;
    }*/
    
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {


}