/**
*   card-tables.scss
*   @description: Estilos de tarjetas de tablas
*/

.card-table {
    // height: 370px;
    border-radius: 4px;
    padding: $paddingMedium;
    background-color: $white;
    box-shadow: 1px 0 20px rgba(0,0,0,.05);
    ::-webkit-scrollbar { width: 4px; }
    ::-webkit-scrollbar-thumb { background-color: #cccccc; }

    th {
        padding: $paddingNormal 0;
        border-bottom: 2px solid rgba($primary, 0.4);
    }

    td {
        padding:  8px 8px 8px 0px;
	}
	
	.th-options {
		width: 120px !important;
	}

	.th-options-small {
		width: 94px !important;
	}

	.th-options-huge {
		width: 250px !important;
	}

    .animation-rotate {
        animation: fa-spin 2s infinite linear;
    }

    thead {
        tr {
            &:hover {
                background-color: $white;
            }
        }
    }
    tr {
		transition: 300ms ease-in-out;

		&.row-active {
			background-color: #c3c3c3;
		}

        &:hover { 
			background-color: rgba(#f2f2f2, 0.6);
        }

    }

}

.card-row-edit {
    height: auto;
    margin: 24px;
    width: 35%;
    font-family: $Roboto;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: white;

    .grid-edit {
        padding: 10px 14px 4px 14px;
    }

    .grid-content {
        padding: 24px;
    }
}