.card{
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 1px 0 20px rgba(0,0,0,.05);
}

.box {
    height: auto;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(33,33,33,.1);

    .header-box {
        position: relative;
        padding: 12px 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: 1px solid #eee;
        BACKGROUND-COLOR: #9e9e9e4d;

        .close {
            position: absolute;
            top: 0px;
            right: 10px;
        }

        .close-pdf {
            position: absolute;
            top: 7px;
            right: 10px;

            i {
                font-size: 0.9em;
            }
        }
    }

    .barcode-qr {
        svg {
            width: 40%;
            height: 108px;
        }

        canvas {
            padding-bottom: 16px;;
            width: 30% !important;
            height: auto !important;
        }
    }

    .content-box {
        padding: 0px 24px;
    }
}

.box-edit, .box-edit-pdf {
    height: auto;
    background-color: white;
    border-radius: 4px;
    margin: 24px;
    box-shadow: 0 0 3px rgba(33,33,33,.1);

    .header-box {
        padding: 12px 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: 1px solid #eee;
        BACKGROUND-COLOR: #9e9e9e4d;
    }

    .content-box {
        padding: 0px 24px;
    }
}

.box-edit-pdf {
    width: 35%;

    .header-box {
        position: relative;

        .close-pdf {
            position: absolute;
            top: 7px;
            right: 10px;
            font-size: 0.8em;
        }
    }

    .barcode-qr {
        svg {
            width: 40%;
            height: 108px;
        }

        canvas {
            padding-bottom: 16px;;
            width: 30% !important;
            height: auto !important;
        }
    }
}

.card-grid {
    height: /*270px*/295px;
    font-family: $Roboto;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: white;

    .grid-edit {
        padding: 10px 12px 10px 10px;
    }

    .grid-image {
        height: 100%;
        position: relative;

        i {
            font-size: 5em;
            color: $gray;
        }
    }

    .grid-info {
        height: 100%;
        padding: 10px 16px 15px;

        .first-text h3 {
            font-size: 18px;
            font-weight: 600;
        }

        .second-text h3 {
            font-size: 14px;
            font-weight: 600;
        }

        .third-text h3 {
            font-size: 16px;
            font-weight: 600;
        }
    }

    &:hover {
        /*.grid-image img {
            transform: scale(1.2);
            
        }*/

        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
    }
}

.card-grid-edit {
    height: auto;
    font-family: $Roboto;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: white;

    .grid-edit {
        padding: 10px 14px 4px 14px;
    }

    .grid-content {
        padding: 0 24px 24px 24px;
    }
}

.card-grid-product {
    //height: /*310px*/336px;
    height: 400px;
    font-family: $Roboto;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: white;

    .grid-hover {
        position: absolute;
        //padding: 10px 12px 10px 10px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 4px;
        width: 100%;
        height: 100%;
        opacity: 1;
        display: none;

        .grid-options {
            position: absolute;
            top: 28%;

            .fa-edit {
                color: white;
            }

            .circle {
                width: 50%;
                color: white;
                border: 1px solid white;
                padding: 18px;
                font-size: 1.6em;
                border-radius: 50%;

                &:hover {
                    transform: scale(1.1);
                }
            }

            .grid-codigos-d {
                width: 60%;
            }

            .grid-codigos-m {
                width: 45%;
            }
        }
    }

    &:hover {
        .grid-hover {
            display: block;
        }
    }

    .grid-img {
        height: 650px/*170px*/;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        img {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            -webkit-transition: -webkit-transform .5s ease;
            transition: -webkit-transform .5s ease;
            transition: transform .5s ease;
            transition: transform .5s ease,-webkit-transform .5s ease;
            height: 200px/*170px*/;
            width: 100%;
            overflow: hidden;
        }
    }

    .grid-info {
        height: 100%;
        padding: 10px 16px 15px;

        .first-text h3 {
            font-size: 18px;
            font-weight: 600;
        }

        .second-text h3 {
            font-size: 14px;
            font-weight: 500;
        }

        .third-text h3 {
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.card-grid-module {
    //height: /*310px*/336px;
    height: 225px;
    font-family: $Roboto;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: white;

    .grid-hover {
        position: absolute;
        //padding: 10px 12px 10px 10px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 4px;
        width: 100%;
        height: 100%;
        opacity: 1;
        display: none;

        .grid-options {
            position: absolute;
            height: 100%;

            .circle {
                width: 25%;
                color: white;
                border: 1px solid white;
                padding: 15px;
                font-size: 1.6em;
                border-radius: 50%;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    &:hover {
        .grid-hover {
            display: block;
        }
    }

    .icon-module {
        .circle-ic {
            width: 30%;
            //color: $pink;
            border: 2px solid $pink;
            padding: 15px;
            font-size: 2.2em;
            border-radius: 50%;
        }
    }
}

    $g: #6D44B5;
$p: #FE8D58;
$o: #F45746;
$gr: #B9ACCA;
$b: #0C011C;
$pi: #fba0cc;
  .card-capacitacion{
    width:400px;
    min-width:400px;
    height:300px;
    position:relative;
    max-width:100%;
    background:#fff;
    *{
      transition:all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    h1,.video{
      position:absolute;
      right:30px;
      z-index:9;
      color:#fff;
      font-size:200px;
      font-weight:100;
      margin:0;
      top:0;
      line-height:0.75;
      transform:scale(0) rotateX(180deg);
      opacity:0;
    }
    .watch-video, .manuales{
      position:absolute;
      z-index:999;
      width:75%;
      left:12.5%;
      -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
      transition:none;
      padding-top: 16px;

      iframe {
        height: 200px;
        width: 100%;
      }
    }
    .video{
      color:$pink;
      font-size:15px;
      bottom:28px;
      top:auto;
      width:50%;
      display:block;
      text-align:center;
      letter-spacing:2px;
      text-transform:uppercase;
      font-weight:900;
      padding:10px 5px;
      border:2px solid;
      cursor:pointer;
      left:-20px;
      opacity:1;
      transform:none;
      //font-family:"Raleway";
      &:before{
        content:'Ver menos';
        position:absolute;
        left:50%;
        top:50%;
        transform:translateX(-50%) translateY(-50%) scale(0);
        font-size:15px;
        opacity:0;
      }
    }

    .pdfs {
      &:before{
        content:'Ver menos';
        position:absolute;
        left:50%;
        top:50%;
        transform:translateX(-50%) translateY(-50%) scale(0);
        font-size:15px;
        opacity:0;
      }
    }

    .manuales {
      padding-top: 40px;

      .img-pdf {
        width: 85%;
      }
    }

    .pdf, .pdfs {
      position:absolute;
      z-index: 5;
      color:$pink;
      font-size:15px;
      bottom:70px;
      top:auto;
      width:50%;
      display:block;
      text-align:center;
      letter-spacing:2px;
      text-transform:uppercase;
      font-weight:900;
      padding:6px 5px;
      border:2px solid;
      cursor:pointer;
      left:-20px;
      opacity:1;
      transform:none;
    }
    .pdf-only, .video-only {
      bottom: 50px;
    }
    .check-video{
      z-index: 999;
      position: absolute;
      bottom: 26px;
      width: 50%;
      left: -20px;
      height: 38px;
      opacity: 0;
      &:hover{
        &~ .video{
          background:$pink;
          color:#fff !important;
          border-color:$pink;
        }
      }
      &:checked{
        &~ .watch-video{
          transition:all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.175);
          transition-delay:0.2s;
          -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
        &~ .video{
          z-index:100;
          color:$pink;
          border-color: $pink;
          font-size:0;
          padding: 17px 0px;
          width: 56%;
          &:before{
            opacity:1;
            transform:translateX(-50%) translateY(-50%) scale(1);
          }
        }
        &~ .blob{
          z-index:99;
          transition:all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
          &:before, &:after{
              transform:scale(0);
            }
          .glob{
            transition:all 400ms cubic-bezier(0.175, 0.885, 0.32, 1);
            min-width:110%;
            min-height:110%;
            border-radius:0px;
            top:-5%;
            right:-5%;
            
          }
        }
      }
    }

    .check-manuales{
      z-index: 999;
      position: absolute;
      bottom: 70px;
      width: 50%;
      left: -20px;
      height: 38px;
      opacity: 0;
      &:hover{
        &~ .pdfs{
          background:$pink;
          color:#fff !important;
          border-color:$pink;
        }
      }
      &:checked{
        &~ .manuales{
          transition:all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.175);
          transition-delay:0.2s;
          -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
        &~ .pdfs{
          z-index:100;
          color:$pink;
          border-color: $pink;
          font-size:0;
          padding: 6px 0px;
          width: 56%;
          &:before{
            opacity:1;
            transform:translateX(-50%) translateY(-50%) scale(1);
          }
        }
         &~ .blob{
          z-index:99;
          transition:all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
          &:before, &:after{
              transform:scale(0);
            }
          .glob{
            transition:all 400ms cubic-bezier(0.175, 0.885, 0.32, 1);
            min-width:110%;
            min-height:110%;
            border-radius:0px;
            top:-5%;
            right:-5%;
            
          }
        }
      }
    }
    input[type="radio"]+div{
      overflow:hidden;
      position:absolute;
      width:100%;
      height:100%;
      left:0;
      top:0;
    }
    input[type="radio"]:checked+div h1{
      transform:scale(1) rotate(0deg);
      opacity:1;
    }
    input[type="radio"]:nth-of-type(2):checked+div ~ .blob{
      .glob{
        width:70%;
        height:75%;
        background:$p;
      }
      &:before{
          top:40%;
           background:$p;
        }
      &:after{
        background:$p;
      }
    }
    input[type="radio"]:nth-of-type(3):checked+div ~ .blob{
      .glob{
        width:70%;
        height:55%;
        background:$o;
      }
      &:before{
          top:20%;
           background:$o;
        left:40%;
        }
      &:after{
        background:$o;
        left:70%;
      }
    }
    input[type="radio"]:nth-of-type(1):checked+div ~ .photo div{
      &:nth-of-type(1){
        transform:rotateX(0deg);
      }
    }
    input[type="radio"]:nth-of-type(2):checked+div ~ .photo div{
      &:nth-of-type(2){
        transform:rotateX(0deg);
      }
    }
    input[type="radio"]:nth-of-type(3):checked+div ~ .photo div{
      &:nth-of-type(3){
        transform:rotateX(0deg);
      }
    }
    input[type="radio"]:nth-of-type(2):checked+div ~ .shapes{
        top:35%;
        left:65%;
      width:90px;
      &:before{
        left:120px;
        top:170px;
        -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
      }
    }
    input[type="radio"]:nth-of-type(3):checked+div ~ .shapes{
        top:55%;
        left:55%;
      width:140px;
      &:before{
        left:140px;
        top:-40px;
        -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
clip-path: polygon(100% 0, 0 0, 100% 100%);
      }
    }
    &:before{
      content:'';
      position:absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      background:transparent;
      box-shadow:0 10px 30px -10px rgba(0,0,0,0.5);
      z-index:0;
    }
    .photo{
      position:absolute;
      width:50%;
      height:100%;
      z-index:0;
      overflow:hidden;
      perspective:300px;
      div{
        position:absolute;
        width:75%;
        height:50%;
        transform:rotateX(-180deg);
        top:-12.5%;
        margin-left:-12.5%;
        transform-style:preserve-3d;
        backface-visibility:hidden;
        border-radius:100%;
        box-shadow:inset 0 0 0 5px #fff, 0 0 0 5px $pink, 0 0 0 10px #fff;
        &:before{
          content:'💯';
          font-size:75px;
          position:absolute;
          left:50%;
          top:50%;
          margin:20px 0 0 5px;
          transform:translateX(-50%) translateY(-50%);
          filter:saturate(0) brightness(20);
          opacity:0.25;
        }
        &:first-of-type{
          background:$pink;
        }
        &:nth-of-type(2){
          background:$o;
          box-shadow:inset 0 0 0 5px #fff, 0 0 0 5px $o, 0 0 0 10px #fff;
          &:before{
            content:'👯';
          }
        }
        &:nth-of-type(3){
          background:$gr;
          box-shadow:inset 0 0 0 5px #fff, 0 0 0 5px $gr, 0 0 0 10px #fff;
          &:before{
            content:'💋';
          }
        }
      }
    }
    .shapes{
      position:absolute;
      left:45%;
      top:20%;
      background:$p;
      width:60px;
      height:4px;
      z-index:2;
      box-shadow:0 8px 0 0 $p, 0 16px 0 0 $p, 0 24px 0 0 $p, 0 32px 0 0 $p;
      mix-blend-mode:screen;
      &:before{
        content:'';
        position:absolute;
        width:40px;
        height:40px;
        background:linear-gradient(to bottom, $p, $o);
        -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        left:175px;
        top:160px;
        transition:all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
    .blob{
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
      filter:url(#goo);
      .glob{
        position:absolute;
        width:60%;
        height:75%;
        background:$primary;
        border-radius:100%;
        top:-16%;
        right:-16%;
      }
      &:before, &:after{
        content:'';
        position:absolute;
        width:50%;
        height:60%;
        background:$primary;
        border-radius:100%;
        right: 51px;
        top:20px;
        transition:all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
      &:after{
        right:-3%;
        top:20%;
        width:40%;
        height:60%;
      }
    }

    .circle-ic {
      i {
        font-size: 4.5rem;
        color: $secondary;
        right: 40px;
        top: 40px;
        position: absolute;
        z-index: 3;
      }

      h4 {
        color: $secondary;
        position: absolute;
        z-index: 3;
        right: 24px;
        top: 120px;
      }
    }

    .grid-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 5rem 1.5rem;
    }

    .grid-3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 5rem 1.5rem;
    }
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
    .card-row-edit {
        width: 40% !important;
    }

    .grid-edit {
        padding: 14px 12px 10px 10px !important;
    }
}

.swal-title {
    font-size: 18px !important;
}

.swal-button {
  //background-color: $secondary;
}

.swal-button:not([disabled]):hover {
  //background-color: $primary;
}