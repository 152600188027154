#drop_zone {
	display: flex;
	border: 2px dashed #ccc;
	border-radius: 20px;
	width: 480px;
	height: auto;
	min-height: 200px;
	margin: 50px auto;
	padding: 20px;
	justify-content: center;
    align-items: center;

    &::backdrop {
        border-color: purple;    
    }
}


// #drop_zone.highlight {
// 	border-color: purple;
// }
// p {
// 	margin-top: 0;
// }

// #my-form {
// 	margin-bottom: 10px;
// }
// #gallery {
// 	margin-top: 10px;
// }
// #gallery img {
// 	width: 150px;
// 	margin-bottom: 10px;
// 	margin-right: 10px;
// 	vertical-align: middle;
// }
// .button {
// 	display: inline-block;
// 	padding: 10px;
// 	background: #ccc;
// 	cursor: pointer;
// 	border-radius: 5px;
// 	border: 1px solid #ccc;
// }
// .button:hover {
// 	background: #ddd;
// }
// #fileElem {
// 	display: none;
// }
