

.btn {
    cursor: pointer;
    font-family: $Roboto;
    font-size: $fontSmall;
    font-weight: $weightBold;
    background-color: transparent;
    transition: all 200ms ease-in-out;
    &:active { transform: scale(0.95); }
    width: 100%;
	background-color: $gray;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	border-radius: 3px;
    padding: $paddingNormal $paddingSemi;
    /*-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
    -o-border-radius: 3px;*/
    color: white;
}

.btn-modal {
    cursor: pointer;
    font-size: $fontSmall;
    font-weight: $weightBold;
    background-color: transparent;
    transition: all 200ms ease-in-out;
    &:active { transform: scale(0.95); }
    width: 100%;
	background-color: $gray;
	text-align: center;
	font-size: 15px;
	font-weight: bold;
    padding: $paddingNormal $paddingSemi;
    color: white;
}

.btn-primary {
    //background-color: rgba($primary, 0.8);
	//box-shadow: 5px 4px 7px lightgrey;
    //color: black;
    &:hover { 
        background-color: rgba($primary, 0.8);
        color: black; 
    }
}

.btn-secondary {
    background-color: $secondary;
	//box-shadow: 5px 4px 7px lightgrey;
    color: white;
    &:hover { 
        background-color: rgba($primary, 0.8);
        color: black; 
    }

    &:disabled {
        cursor: not-allowed;
        background-color: #cccccc;
        color: #666666;
    }
}

.btn-transparent{
    background-color: transparent !important;
}

.btn-small
{
    width: 30%;
}

.btn-mini
{
    width: 20%;
}

.btn-login{
    width: 70%;
}

.btn-success {
    background-color: $green;
}

.btn-cancel {
    background-color: darkred;
}

.btn-red {
    background-color: #dc0e0e;
}

.btn-blue {
    background-color: #3F51B5;
}

.btn-color-primary {
    background-color: $primary;
}

.btn-color-secondary {
    background-color: $secondary;
}

.btn-disabled {

}

.link {
    i {
        color: $secondary;
    }

    color: #4797c5;
    text-decoration: underline;

    &:hover {
        color: darkblue;
    }
}

.btn-Listview{
    width: 40%;
    padding: 5px 4px;
    border: 1px solid rgb(155, 155, 155);
    border-radius: 2px;
    margin: 4px;
    background-color: rgb(155, 155, 155);
    cursor: pointer;

    &:hover {
        border: 1px solid $green;
        border-radius: 2px;
        margin: 4px;
        background-color: $green;
    }
}

.btn-Listview i {
    color: white;
    font-size: 1.2em;
}

.fa-trash {
    color: $secondary;
}

/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {



}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {    
    

}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
    .btn-small
    {
        width: 100% !important;
    }
    
    .btn-mini
    {
        width: 100%;
    }

}